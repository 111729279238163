import React, { useState } from "react";

import img1 from './BP.jpg';
import img2 from './BP1.jpeg';
import img3 from './BP2.jpg';
import img4 from './BP3.png';
import img5 from './BPC1.png';
import img6 from './BPC2.png';
import img7 from './BPC3.png';
import img8 from './BPC4.png';

import "./ExpCards.css";

export default function ExpCards() {
  const [Blocks] = useState([
    {
      Tech: [
        {
          imgURL: img6,
          ExpTitle: "Tracking Prices of Produce During Uncertain Times",
          subtext: "Meat shortage and how to ensure you get the best price!",
          additional: `This python script was more important than ever during the pandemic, as meat shortages had become a concern for many consumers. With this script, you can stay up-to-date on the availability and price of different types of meat at wholesalers within the GTA, ensuring that you can stock up on the items you need when they are available at a reasonable price.

          Not only did script allow you to track the price and availability of meat, it also included advanced features such as the ability to analyze the data over time to detect any changes in the price or availability of different types of meat. This is particularly useful for anyone looking to plan their meat purchases in advance and ensure that they can secure a consistent supply at a reasonable cost.
          
          In addition to its practical uses, the python script also includes a user-friendly interface that allows you to easily view the data. With just a few clicks, you can view detailed graphs and charts that show the price and availability trends for different types of meat over time, helping you to make informed decisions about your meat purchases.
          
          In uncertain times, this python script was an essential tool for anyone looking to stay on top of the availability and price of meat. It was created using Selenium, pandas, numpy and pyplot. The database was made with SQLite3.`,
        },
        {
            imgURL: img7,
            ExpTitle: "Automated emails sent through Proton Mail",
            subtext: "Why re-invent the wheel?",
            additional: "This python script was required to easily send customized emails to hundreds of clients using Proton Mail. There was no need to manually compose and send each email individually - simply inputting your recipient list and choosing a few dynamic boilerplates, the script will handle the rest. This script was created out of a need to send custom emails through Proton Mail, which was not programmable through traditional APIs at the time. This project saved time and improved productivity. It was created using Selenium and SQLite3.",
          },
          {
            imgURL: img5,
            ExpTitle: "Discord Bot, All-in-one Crypto Tracker",
            subtext: "Inspired by the anime Jujutsu Kaisen",
            additional: "This Project was created using Node.js, Chart.js, Puppeteer, Cron, Cheerio and hosted on an AWS EC2. The bot was designed to provide users with real-time updates on the prices and movements of various cryptocurrencies, as well as information on non-fungible tokens (NFTs). It utilized various APIs and web scraping techniques to gather data from various sources, including etherscan.io, crypto oracles, open sea, gem.io, and others. It also kept track of users' asset holdings and digital asset trades, and provided charts to help users visualize market trends. In addition to providing up-to-date information on the crypto market, the bot also kept users informed about relevant news and updates from reputable sources like CoinDesk and CoinTelegraph. Overall, the bot was a valuable tool for anyone interested in staying informed about the rapidly-evolving world of cryptocurrency and NFTs.",
          },
            {
                imgURL: img8,
                ExpTitle: "Miscellaneous Projects",
                subtext: "To achieve anything in this game, you must be prepared to dabble on the boundary of disaster.",
                additional: "Other than the above mentioned tools, I've experimented with other frameworks such as Rust and Nuxt.js. This website being built on Next.js. I've also created a myriad of other projects in my free time, for instance, and LED Matrix dashboard built on and ESP32 that reports server outages and other information such as news, time and weather using local Express JS GET endpoints. Other projects such as webcrawlers to see if GPUs are back in stock using node-fetch and axios. Working on other embedded systems such as battery-backed LORAwan transmitters that are off network to monitor home server uptime statuses, so in cases where WIFI and cell network are compromised or there is an outage, there is still a log of details and a potential to alert administrators.     ",
              },
          
      ],
    },
    {
      physio: [
        {
          imgURL:
            img3,
          ExpTitle: "Providence Rehab : Neuro and Stroke Rehab",
          subtext: "This Internship focused mainly on treating stroke Patients.",
          additional: "During my six-week clinical internship at a 24-bed inpatient neuro rehabilitation unit at Providence Rehab center in Scarborough, Ontario, I was responsible for the evaluation, treatment, education, and discharge planning of a full Neuro patient load. I treated patients with conditions such as stroke, spinal cord injury, traumatic brain injury, neuropathy, encephalopathy, Parkinson's disease, and acquired brain injuries (ABIs). I collaborated with a variety of healthcare professionals, including physicians, occupational therapists, speech-language pathologists, nurses, social workers, and neuropsychologists, to provide comprehensive rehabilitation services for adult and geriatric patients. In addition to treating patients, I also participated in multi-disciplinary rounds, patient/family conferences, and conducted family/caregiver education and training. I also had the opportunity to expertly utilize a body weight supported treadmill to help patients with hemiplegia improve their gait and perform activities of daily living (ADLs) more independently. Overall, it was a valuable and rewarding experience that allowed me to develop and refine my skills as a rehabilitation therapist.",
        },
        {
          imgURL:
          img1,
          ExpTitle: "Hennick Bridgepoint Hospital : Medical Rehab",
          subtext: "This rotation had a focus mainly on Complex Continuing Care and post-surgical patients.",
          additional: `As a Clinical and Administrative Intern at an inpatient medical rehabilitation unit, I had the opportunity to collaborate with hospital administration and management on defining formal parameters for rehabilitation care delivery plans. I also liaised with managers and directors to support meetings regarding hospital operations. These experiences allowed me to develop my leadership skills and become a valuable member of the professional practice team.
          In addition to my administrative duties, I was responsible for the evaluation and treatment of patients with complex conditions and chronic pain, under the supervision of a clinical instructor. I provided documentation to support the delivery of physical therapy to patients with diagnoses such as fractures, CABG, joint replacements, muscle weakness, osteoarthritis, falls, chronic fatigue syndrome, and post-operative complications. Throughout my internship, I proficiently used MediTech for patient charting and querying patient history, which allowed me to effectively diagnose and treat patients as a physiotherapist.`,
        },
        {
          imgURL:
          img4,
          ExpTitle: "Scarborough Health Network: Acute Care PT",
          subtext: "Clinical Emphasis on medically unstable, ICU and Ortho Patients.",
          additional: `As a PT Student intern at a hospital, I had the opportunity to evaluate and treat patients with a wide range of diagnoses and medical conditions on various units, including surgical, general medicine, ICU/MICU/SICU, and step down telemetry. I worked closely with multidisciplinary teams to develop individualized care plans for each patient, taking into account their impairments and selecting appropriate interventions and treatments. I also set short and long-term goals for each patient and made discharge recommendations and discharge planning. I attended and participated in multidisciplinary meetings and rounds with the rehabilitation team, primary team physicians, social workers, and head nursing staff to optimize the patient's plan of care and maximize their rehabilitation potential with regard to functional mobility, safety, quality of life, and burden of care. Throughout my internship, I demonstrated proficiency in using the EPIC hospital information system for patient charting and querying patient history.`,
        },
        {
          imgURL:
            img1,
          ExpTitle: "Preferred Rehab Scarborough: Outpatient PT",
          subtext: "Clinical Rotation with a focus on sports, MVA, WSIB related and MSK injuries.",
          additional: `As a physiotherapist student in an outpatient setting, I reviewed patient medical history and referral notes to understand health problems and suggest appropriate physiotherapy regimens to facilitate movement for daily activities. I evaluated the effectiveness of treatment plans and implemented modifications as needed. I conducted clinical assessments and evaluations to determine patient needs and diagnoses, and demonstrated proper form and monitored patients during exercises. I tracked and evaluated patient progress with respect to treatment goals, and updated and maintained charts to reflect on patient progress and treatments. I also provided instructions and collaborated with physiotherapy assistants to administer physiotherapy interventions. In addition, I updated and maintained records related to WSIB and MVA claims, and utilized Jane online charting software to track patients' progress and collaborate with other healthcare professionals, such as chiropractors, registered massage therapists, and doctors. As an outpatient physiotherapist, I worked closely with patients to help them achieve their goals and improve their overall quality of life.
`,
        },
      ],
    },
  ]);
  function ExpCardsTemplate(props) {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
      setActive(!isActive);
    };
    return (
      <div class={`card ${isActive ? "active" : ""}`} onClick={toggleClass}>
        <div class="card-header">
          <img src={props.imgURL} alt="rover" />
        </div>
        <div className="card-wrapper">
          <div class="card-body">
            <h4 className="ExpTitle">{props.ExpTitle}</h4>
            <p classname="subtext">{props.subtext}</p>
            <p
              class={`additional ${isActive ? "active" : ""}`}
              onClick={toggleClass}
            >
              {props.additional}
            </p>
            <p className="movingtext">Click Here to view more</p>
          </div>
        </div>
      </div>
    );
  }
  let pathAmounts = [];
  let whatPage = window.location.pathname;
  if (whatPage === "/physio") {
    pathAmounts.push(1, "physio");
  } else if (whatPage === "/programming") {
    pathAmounts.push(0, "Tech");
  }
  function MapExpInfo({ Blocks, path }) {
    return Blocks[path[0]][path[1]].map((info) => {

      return <ExpCardsTemplate {...info} />;
    });
  }

  return (
    <>
      <div className="bigwrapper">
        <div className="container">
          <MapExpInfo Blocks={Blocks} path={pathAmounts} />
        </div>
      </div>
    </>
  );
}
