import React, { useState, useRef, useEffect } from "react";
import { Link, Route, Routes,useLocation, Navigate} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom/client";
// import './index.css';
import reportWebVitals from "./reportWebVitals";
import FinalExpInfo from "./ExpCards.js";
import "./MainCards.css";
import physioLogo from "./physiosymbol.svg";
import aboutme from "./about me.svg";
import termianlsvg from "./terminal_FILL0_wght200_GRAD0_opsz48.svg";
import CVPage from "./CVPage.js";
import img13 from "./IS1.jpg";
import img14 from "./Is2.png";
import img15 from "./IS3.jpg";


function App() {
  // const Cardinfo=[
  //    {
  //       imglink:"url(https://www.choicehealthcentre.com/wp-content/uploads/2021/05/physiotherapy-treatment-in-thrissur-5.jpeg)",
  //       logo:physioLogo,
  //       title:"Physio",
  //       sub:"My Journey as a PT"
  //    }
  // ]


  //json that holds fills the main cards
  const [Cardinfo] = useState([
    {
      imglink:
      `url(${img13})`,
      logo: physioLogo,
      title: "Physio",
      sub: "My Journey as a PT",
      sent: "/physio",
    },
    {
      imglink:
      `url(${img14})` ,
      logo: aboutme,
      title: "Biography",
      sub: "Find my CV!",
      sent: "/me",
    },
    {
      imglink:
      `url(${img15})` ,
      logo: termianlsvg,
      title: "Programming",
      sub: "See my projects!",
      sent: "/programming",
    },
  ]);



  //Creat the Card component
  function MainCards(props) {
    return (
      <>
        <Link to={props.sent} style={{display:"contents"}}> 
        <div
          className="option"
          style={{
            background: props.imglink,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: "85%",
          }}
        >
          
          <div className="shadow">
            <div className="label">
              <div className="icon">
                {" "}
                <img className="logo" src={props.logo} alt="" />
              </div>
              <div className="info">
                <div className="main">{props.title}</div>
                <div className="sub">{props.sub}</div>
              </div>
            </div>
          </div>
        </div>

        </Link>
      </>
    );
  }

  //fill cards with information from the json
  function MapCardInfo({ Cardinfo }) {
    return Cardinfo.map((info) => {
      // return <MainCards key={info.title}info={info}/>
      return <MainCards {...info} />;
    });
  }

//uses Above two to create the home page with the cards
 function PageHome(){
   return(
     
<>
    <motion.div initial={{ opacity:0}} animate= {{opacity:1}} exit={{opacity:0}} transition={{duration: 0.1}} >
        <body>
        <div className="homePageWrapper">
       <div className="myName">
        Abrar Patel
         <div className="subName">Hons. BSc, MScPT</div>
       </div>
       <div className="options">
         <MapCardInfo Cardinfo={Cardinfo} />
       </div>
       </div> 
      </body>
      </motion.div>
        </>
   )
 }
 function PagePhysio(){
  return(
    
<>

<motion.div initial={{opacity:0}} animate= {{opacity:1}} exit={{opacity:0}} transition={{duration: 0.1}}>
         <Link to="/">
      <div className="myName">
       Abrar Patel
        <div className="subName">Hons. BSc, MScPT</div>
      </div></Link>
      <Link to="/" class="previous round">&#8249;</Link>
      <FinalExpInfo/>

      </motion.div>
     
       </>
  )
}

function AboutMe(){
  return(
    
<>

<motion.div initial={{opacity:0}} animate= {{opacity:1}} exit={{opacity:0}} transition={{duration: 0.1}}>
         <Link to="/">
      <div className="myName">
       Abrar Patel
        <div className="subName">Hons. BSc, MScPT</div>
      </div></Link>
      <Link to="/" class="previous round">&#8249;</Link>
      <CVPage/>

      </motion.div>
     
       </>
  )
}


 //Need to design the additional pages that each section goes to



  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
  const location= useLocation();
  return (
    
    <AnimatePresence exitBeforeEnter initial={false}>
    <Routes location={location} key={location.pathname}>
      {/* HOME PAGE */}
      <Route path="/" element= {
          <PageHome/>}
      >  
      </Route>

      {/* PHYSIO PAGE */}
      <Route path="/physio" element= {
          <PagePhysio/>}
     
      >  
      </Route>

      {/* ME PAGE */}
      <Route path="/me" element= {
          <AboutMe/>}
      >  
      </Route>

      {/* PROGRAMMING PAGE */}
      <Route path="/programming" element= {
           <PagePhysio/>}
      >  
      </Route>

      {/* ERROR PAGE */}
      <Route path="*" element={<Navigate to="/" replace />} />

      
    </Routes>
    </AnimatePresence>
  );
}

export default App;
